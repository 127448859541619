<template>
    <div>
        <div class="chapters-content-wrap">
            <div class="chapters">
                <div class="content-head">
                    <h1>{{ $t('article_tab_content.general_info') }}</h1>

                    <div class="title-input">
                        <label for="title">{{ $t('article_tab_content.title') }}</label>
                        <input :disabled="titleFieldDisabled()" id="title" type="text" v-model="title">
                    </div>

                    <label>{{ $t('article_tab_content.primary_image') }}</label>

                    <div class="component">
                        <div class="component--content">
                            <div class="image-uploader" v-if="! article.featured_image">
                                <input @change="previewImage" accept="image/*" ref="image" style="display: none" type="file" @blur="updateTitle">

                                <p>{{ $t('upload_component.add_files') }}</p>
                                <span>{{ $t('upload_component.or') }}</span>

                                <a href="#" @click.prevent="$refs.image.click()" class="btn btn--blue">
                                    {{ $t('upload_component.add_image') }}
                                </a>
                            </div>

                            <div class="image-preview" v-if="article.featured_image">
                                <img :src="article.featured_image" v-if="article.featured_image.includes('base64')">
                                <img :src="'/files/articles/' + article.featured_image" v-else />
                                <a @click.prevent="removeImage" href="#" v-if="article.published_at === null">
                                    {{ $t('upload_component.delete_image') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="chapter article">
                    <div class="article-header">{{ $t('article_tab_content.content') }}</div>
                    <div class="chapter-content article-content">
                        <component-list :components="article.components" :data="article" :language="language" :type="'article'" />
                    </div>
                </div>
            </div>

            <div class="chapters-navigation">
                <div class="chapters-navigation-header">
                    <h3>{{ $t('article_tab_content.settings') }}</h3>
                </div>

                <div class="chapters-navigation-items">
                    <div class="item item--datepicker">
                        <span>{{ $t('article_tab_content.publish_at') }}</span>
                        <datetime v-model="publishDate" :placeholder="$t('article_tab_content.select_date')" type="datetime" :min-datetime="disabledDates.to.toISOString()"></datetime>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash.debounce'
    import ComponentList from "./ComponentList";
    import moment from "moment";
    import { Datetime } from 'vue-datetime';
    import { Settings } from 'luxon';

    Settings.defaultLocale = 'nl';

    export default {
        name: 'article-tab-content',
        components: { ComponentList, Datetime },
        props: {
            article: {
                required: true,
            },
            language: {
                required: true,
            }
        },

        data() {
            return {
                title: null,
                oldLanguage: null,
                publishDate: null,
                disabledDates: { to: new Date() },
            }
        },

        created() {
            this.debouncedUpdateComponent = debounce(this.updateTitle, 1500);
            this.setContent();

            setTimeout(() => {
                this.publishDate = this.article.publish_at ? new Date(this.article.publish_at).toISOString() : null;
            }, 400);
        },

        watch: {
            title(newVal, oldVal) {
                if (oldVal !== null) {
                    this.debouncedUpdateComponent();
                }
            },

            publishDate(newVal, oldVal) {
                this.updatePublishAtDate();
            },

            article() {
                this.setContent()
            },

            language() {
                if (this.oldLanguage && this.oldLanguage.code !== this.language.code) {
                    this.updateTitle()
                }
            },
        },
        methods: {
            setContent() {
                if (this.article.id) {
                    if (this.oldLanguage === null) {
                        this.oldLanguage = this.language;
                    }
                    let self = this;
                    let translation = this.article.translations.filter(function (translations) {
                        return translations.language === self.language.code
                    })[0];

                    this.title = translation.content;

                    this.oldLanguage = this.language
                }
            },

            updateTitle() {
                this.$store.dispatch('articles/set_article_title', {
                    title: this.title,
                    language: this.oldLanguage,
                });

                if (this.oldLanguage.code !== this.language.code) {
                    this.oldLanguage = this.language;
                    this.setContent();
                    return;
                }

                this.$resetMessages();
                this.$flashMessage(this.$t('article_tab_content.article_success_flash'), 5000, 'succes')
            },

            updatePublishAtDate() {
                const date = this.publishDate ? moment(this.publishDate).format('YYYY-MM-DD HH:mm:ss') : null;

                this.$store.dispatch('articles/set_article_publish_at_date', {
                    date: date
                });

                this.$resetMessages();
                this.$flashMessage(this.$t('article_tab_content.article_success_flash'), 5000, 'succes');
            },

            removeImage() {
                this.article.featured_image = '';
                this.$store.dispatch('articles/remove_article_image');
            },

            titleFieldDisabled() {
                if (this.article.published_at !== undefined) {
                    if (this.article.published_at !== null) {
                        return true
                    }
                } else {
                    if (this.article.published_at === null) {
                        return true
                    }
                }
                return false
            },

            previewImage(event) {
                let input = event.target;

                if (input.files && input.files[0]) {
                    let reader = new FileReader();

                    reader.onload = (e) => {
                        if (e.total > 22000000) {
                            this.$flashMessage(this.$t('article_tab_content.image_to_large'), 5000);
                            return;
                        }
                        this.article.featured_image = e.target.result;
                        this.$store.dispatch('articles/set_article_image', { image: e.target });
                        this.$resetMessages();
                        this.$flashMessage(this.$t('article_tab_content.article_success_flash'), 5000, 'succes')
                    };

                    reader.readAsDataURL(input.files[0]);
                }
            },
        },
    };
</script>
