<template>
    <div>
        <div class="language-select header">
            <vue-multiselect
                id="languages"
                v-model="language"
                v-bind="translations"
                :allow-empty="false"
                :options="languages"
                :searchable="false">
                <template slot="singleLabel"
                          slot-scope="props">
                    <img :src="'/svg/' + props.option.code + '.svg'"
                         alt="country flag">
                    <span v-text="props.option.name" />
                </template>
                <template slot="option"
                          slot-scope="props">
                    <div class="option__desc">
                        <img :src="'/svg/' + props.option.code + '.svg'"
                             alt="check">
                        <span class="option__title">{{ props.option.name }}</span>
                    </div>
                </template>
            </vue-multiselect>
        </div>
        <div class="w-25 mb-large mt-5">
            <vue-multiselect
                :close-on-select="true"
                :multiple="false"
                :options="businessUnits"
                :searchable="false"
                :placeholder="this.$t('manual_tab_divisions.all_divisions')"
                id="businessUnits"
                label="name"
                v-model="selectedBusinessUnit"
                selected-label=""
                select-label=""
                deselect-label=""
                @input="getStatistics"
                track-by="id">
                <template slot="tag" slot-scope="props">
                    <span v-text="props.option.name + ', '"></span>
                </template>
                <template slot="option"
                          slot-scope="props">
                    <div class="option__desc">
                        <span class="option__title">{{ props.option.name }}</span>
                        <img alt="check"
                             class="selected" src="/svg/check.svg">
                    </div>
                </template>
            </vue-multiselect>
        </div>
        <div class="statistics-content-wrap-header">
            <h2>{{ $t('statistics_app_news.news_opened') }}</h2>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th class="w-60">{{ $t('statistics_app_news.news_article') }}</th>
                    <th class="w-20">{{ $t('statistics_app_news.published_at') }}</th>
                    <th class="w-20">{{ $t('statistics_app_news.opened') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="article in articles"
                    :key="article.id">
                    <td class="article-title">
                        <span class="d-block">{{ article.title }}</span>
                        <span class="business-units">{{ article.businessUnits }}</span>
                    </td>
                    <td>{{ moment(article.publishedAt).format('DD-MM-YYYY') }}</td>
                    <td>
                        {{
                            article.total + ' / ' + parseFloat('' + (article.total / totalAmountOfUsers) * 100).toFixed(2) + '%'
                        }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import moment from 'moment';
    import VueMultiselect from 'vue-multiselect/src/Multiselect';
    import businessUnitService from '../../services/business-units';

    export default {
        name: 'StatisticsTabNews',

        components: {
            VueMultiselect,
        },

        data() {
            return {
                articles: null,
                language: {},
                translations: {
                    placeholder: this.$t('statistics_app_news.select_language'),
                    label: 'name',
                    'track-by': 'code',
                    selectLabel: '',
                    selectedLabel: '',
                    deselectLabel: '',
                },
                languages: [],
                selectedBusinessUnit: null,
                businessUnits: [],
                totalAmountOfUsers: null,
            };
        },

        beforeMount() {
            this.getStatistics();
            this.getBusinessUnits();
        },

        beforeRouteEnter(to, from, next) {
            window.axios.get('/languages').then(response => {
                let languages = response.data;
                let language = window.authUser.language_relation ||
                               this.languages.find(language => {
                                   return language.default === 1;
                               });

                next((vm) => {
                    vm.languages = languages;
                    vm.language = language;
                });
            });
        },

        methods: {
            getStatistics() {
                const url = '/statistics/articles' + (this.selectedBusinessUnit ? '/' + this.selectedBusinessUnit.id : '');

                window.axios.get(url, {
                    params: {
                        language: this.language.code,
                    },
                }).then((response) => {
                    this.articles = response.data.articles;
                    this.totalAmountOfUsers = response.data.totalAmountOfUsers;
                });
            },
            getBusinessUnits() {
                businessUnitService.fetch().then((response) => {
                    this.businessUnits = response.data;
                });
            },

            moment: function (date) {
                return moment(date);
            },
        },

        watch: {
            language() {
                this.getStatistics();
            },
        },
    };
</script>
<style lang="scss" scoped>
    .language-select {
        float : right;
    }

    .w-60 {
        width : 60% !important;
    }

    .w-20 {
        width : 20% !important;
    }

    td.article-title {
        &:hover {
            span.business-units {
                white-space : normal;
            }
        }

        span.business-units {
            display       : block;
            white-space   : nowrap;
            width         : 100%;
            text-overflow : ellipsis;
            opacity       : 0.6;
            overflow      : hidden;
            transition    : white-space 2s;
        }
    }
</style>