import Vue from 'vue';
import VueRouter from 'vue-router';
import ManualApp from '../components/ManualApp';
import ArticleApp from '../components/ArticleApp';
import ArticleTabContent from '../components/ArticleTabContent';
import ArticleRelatedArticles from '../components/ArticleRelatedArticles';
import ArticleTabProfile from '../components/ArticleTabProfile';
import ArticleTabDivision from '../components/ArticleTabDivision';
import ArticleTabPushNotifications from '../components/Article/ArticleTabPushNotificationsSend';
import ManualTabContent from '../components/ManualTabContent';
import ManualTabAdjustments from '../components/ManualTabAdjustments';
import ManualTabConfirmed from '../components/ManualTabConfirmed';
import ManualTabDivisions from '../components/ManualTabDivisions';
import PushNotificationsIndex from '../components/PushNotifications/index';
import ManualTabSettings from "../components/ManualTabSettings";
import IdeaBoxApp from "../components/Ideabox/IdeaBoxApp";
import UsersRoutes from "./users";
import EventsRoutes from "./events";
import RolesRoutes from "./roles";
import ContactsRoutes from "./contacts";
import DocumentsRoutes from "./documents";
import ArticlesRoutes from "./articles";
import StatisticsRoutes from "./statistics";
import BusinessUnitRoutes from "./business-units";
import LocationsOnRouteRoutes from './locations-on-route';
import FurloughsRoutes from './furloughs'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/instructies',
    },
    {
        path: '/instructies',
        name: 'instructions',
        component: ManualApp,
        children: [
            {
                path: '',
                redirect: 'content',
                meta: {
                    permissions: ['add-and-edit-instructions']
                },
            },
            {
                path: 'content',
                name: 'content',
                component: ManualTabContent,
                meta: {
                    permissions: ['add-and-edit-instructions']
                },
            },
            {
                path: 'aanpassingen',
                name: 'adjustments',
                component: ManualTabAdjustments,
                meta: {
                    permissions: ['add-and-edit-instructions']
                },
            },
            {
                path: 'akkoord-gegeven',
                name: 'confirmed',
                component: ManualTabConfirmed,
                meta: {
                    permissions: ['add-and-edit-instructions']
                },
            },
            {
                path: 'divisies',
                name: 'divisions',
                component: ManualTabDivisions,
                meta: {
                    permissions: ['add-and-edit-instructions']
                },
            },
            {
                path: 'instellingen',
                name: 'settings',
                component: ManualTabSettings,
                meta: {
                    permissions: ['add-and-edit-instructions']
                },
            },
        ],
    },

    {
        path: '/nieuws/tip-artikel/:id',
        component: ArticleApp,
        children: [
            {
                path: '',
                redirect: 'content',
            },
            {
                path: 'content',
                name: 'content',
                component: ArticleTabContent,
            },
            {
                path: 'related-articles',
                name: 'gerelateerde-artikelen',
                component: ArticleRelatedArticles,
            },
            {
                path: 'profile',
                name: 'profiel',
                component: ArticleTabProfile,
            },
            {
                path: 'divisions',
                name: 'Divisies',
                component: ArticleTabDivision,
            },
            {
                path: 'push-notificatie',
                component: ArticleTabPushNotifications,
            },
        ],
    },

    ...UsersRoutes,
    ...EventsRoutes,
    ...RolesRoutes,
    ...ContactsRoutes,
    ...DocumentsRoutes,
    ...ArticlesRoutes,
    ...StatisticsRoutes,
    ...BusinessUnitRoutes,
    ...FurloughsRoutes,
    ...LocationsOnRouteRoutes,
    {
        path: '/ideeenbus',
        component: IdeaBoxApp,
        name: 'ideabox.index',
        meta: {
            permissions: ['see-ideabox']
        },
    },
    // Push notifications routes
    {
        path: '/push-notificatie',
        component: PushNotificationsIndex,
        name: 'push-notifications.index',
        meta: {
            permissions: ['manage-push-notifications']
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    if (!to.meta.permissions) {
        next();
        return;
    }

    const routePermissions = to.meta.permissions;

    if (router.app.$can(routePermissions)) {
        next();
        return;
    }

    next({ name: 'ideabox.index' });
});

export default router;
