<template>
    <div class="chapters-content-wrap">
        <div class="chapters article adjustments">
            <div class="article-header">{{ $t('manual_tab_adjustments.adjustments') }}</div>
            <div class="chapter-content article-content">
                <component-list
                    :components="computedAdjustment.components"
                    :data="computedAdjustment"
                    :language="language"
                    :type="'adjustment'"
                    :versionDisabled="versionDisabled"
                    v-if="computedAdjustment"
                />
            </div>
        </div>

        <div class="chapters-navigation">
            <div class="chapters-navigation-header">
                <h3>{{ $t('manual_tab_adjustments.settings') }}</h3>
            </div>

            <div class="chapters-navigation-items">
                <div class="item item--datepicker">
                    <span>{{ $t('manual_tab_adjustments.deadline') }}</span>
                    <datepicker :disabled="versionDisabled"
                                :disabled-dates="disabledDates"
                                format="dd-MM-yyyy"
                                :placeholder= "$t('manual_tab_adjustments.pick_date')"
                                v-model="manualVersion.accept_before" />
                </div>

                <div class="item item--select">
                    <span>{{ $t('manual_tab_adjustments.contactperson') }}</span>
                    <select v-model="manualVersion.contact_person_id">
                        <option v-for="contact in contacts" :key="'contact__' + contact.id" :value="contact.id">{{ contact.title }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapMutations } from 'vuex';
    import Datepicker from "vuejs-datepicker";
    import moment from 'moment'

    export default {
        name: 'ManualTabAdjustments',
        components: { Datepicker },
        props: {
            adjustment: {
                required: true,
            },
            language: {
                required: true,
            },
            versionDisabled: {
                required: false,
                default: false,
            },
            manualVersion: {
                required: true,
            },
        },
        data() {
            return {
                disabledDates: { to: new Date() },
                contacts: [],
            }
        },
        watch: {
            adjustment: {
                handler(newVal, oldVal) {
                    if (typeof oldVal !== undefined) {
                        this.setAdjustment(this.adjustment)
                    }
                },
                immediate: true,
            },
            language: {
                handler() {
                    this.setLanguage(this.language)
                },
                immediate: true,
            },
            'manualVersion.accept_before': {
                handler(oldVal, newVal) {
                    if (moment(new Date(oldVal)).format('DD-MM-YYYY') !== moment(new Date(newVal)).format('DD-MM-YYYY')) this.saveDeadline();
                }
            },
            'manualVersion.contact_person_id': {
                handler() {
                    this.saveContactPerson();
                }
            },
        },
        computed: {
            computedAdjustment: {
                set(adjustment) {
                    this.setAdjustment(adjustment);
                },
                get() {
                    return this.$store.getters['adjustments/adjustment'];
                },
            },
        },
        methods: {
            ...mapMutations({
                setAdjustment: 'adjustments/set_adjustment',
                setLanguage: 'adjustments/set_language',
            }),
            saveDeadline() {
                if (this.manualVersion.accept_before) {
                    window.axios.post(`/manuals/manualversion/` + this.manualVersion.id + `/deadline`, {
                        date: moment(new Date(this.manualVersion.accept_before)).format('DD-MM-YYYY')
                    });
                }
            },
            saveContactPerson() {
                window.axios.post(`/manuals/manualversion/` + this.manualVersion.id + `/contact-person`, {
                    contact_person_id: this.manualVersion.contact_person_id
                });
            },
        },
        beforeMount() {
            window.axios.get('/contacts').then((response) => {
                this.contacts = response.data;
            });
        }
    }
</script>
