<template>
    <div class="content">
        <div class="header">
            <h1>{{ $t('events.events') }}</h1>

            <div class="header-buttons">
                <div @click="createEvent">
                    <div class="button green">
                        <span>{{ $t('events.add_event') }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="body">
            <table>
                <thead>
                    <tr>
                        <th class="sortable" :class="{'sorted': this.sortByColumn === 'title'}" @click="sortBy('title')">{{ $t('events.title') }}</th>
                        <th class="sortable" :class="{'sorted': this.sortByColumn === 'date_from'}" @click="sortBy('date_from')">{{ $t('events.date') }}</th>
                        <th class="sortable" :class="{'sorted': this.sortByColumn === 'location'}" @click="sortBy('location')">{{ $t('events.location') }}</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="event in events" :key="'events_' + event.id" @click="editEvent(event)">
                        <td>{{ event.translations[0].title }}</td>
                        <td>{{ humanReadableDate(event.date_from) }}</td>
                        <td>{{ event.location }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'events-index',

        data() {
            return {
                events: [],
                sortByColumn: null,
                sortDirection: 'asc',
            };
        },

        beforeMount() {
            this.fetchEvents();
        },

        methods: {
            createEvent() {
                this.$router.push({ name: 'events.create' });
            },

            editEvent(event) {
                this.$router.push({ name: 'events.edit', params: { id: event.id } });
            },

            fetchEvents() {
                const params = {
                    ...(this.sortByColumn && { orderBy: this.sortByColumn }),
                    ...(this.sortByColumn && { orderByDirection: this.sortDirection }),
                };

                window.axios.get('/events', { params })
                    .then((response) => {
                        this.events = response.data;
                    });
            },

            humanReadableDate(date) {
                return moment(date).format('ddd. DD MMMM YYYY');
            },

            sortBy(s) {
                if (s === this.sortByColumn) {
                    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
                } else {
                    this.sortDirection = 'asc';
                }

                this.sortByColumn = s;
                this.events = [];
                this.fetchEvents();
            },
        },
    }
</script>
