<template>
    <div class="content">
        <div class="header not-sticky">
            <h1>{{ $route.params.id ? $t('event_form.change_event') : $t('event_form.add_new_event') }}</h1>

            <div class="header-buttons">
                <div class="language-select header">
                    <vue-multiselect
                        id="languages"
                        v-model="language"
                        v-bind="langSwitcherOptions"
                        :allow-empty="false"
                        :options="languages"
                        :searchable="false">
                        <template slot="singleLabel" slot-scope="props">
                            <img :src="'/svg/' + props.option.code + '.svg'" alt="country flag">
                            <span v-text="props.option.name"></span>
                        </template>
                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <img :src="'/svg/' + props.option.code + '.svg'" alt="check">
                                <span class="option__title">{{ props.option.name }}</span>
                            </div>
                        </template>
                    </vue-multiselect>
                </div>

                <div @click="save">
                    <div class="button green">
                        <span>{{
                                !$route.params.id ? $t('event_form.add_event') : $t('event_form.save_changes')
                              }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-wrap">
            <div :class="{'content-wrap__content--push-right': !! $route.params.id}" class="content-wrap__content">
                <div class="panel panel--push-top">
                    <h2 class="title title--push-bottom">{{ $t('event_form.data') }}</h2>

                    <div class="field">
                        <label class="label" for="title">{{ $t('event_form.title') }}</label>
                        <input id="title" v-model="title" type="text" />
                    </div>

                    <div class="field">
                        <label class="label" for="date">{{ $t('event_form.date_of') }}</label>
                        <datepicker v-model="dateFrom" :placeholder="$t('event_form.pick_date')" format="dd-MM-yyyy" />
                    </div>

                    <div class="field">
                        <label class="label" for="timeFrom">{{ $t('event_form.time_of') }}</label>
                        <input id="timeFrom" v-model="timeFrom" type="time" />
                    </div>

                    <div class="field">
                        <label class="label" for="date">{{ $t('event_form.date_till') }}</label>
                        <datepicker v-model="dateTo" :placeholder="$t('event_form.pick_date')" format="dd-MM-yyyy" />
                    </div>

                    <div class="field">
                        <label class="label" for="timeTo">{{ $t('event_form.time_till') }}</label>
                        <input id="timeTo" v-model="timeTo" type="time" />
                    </div>

                    <div class="field">
                        <label class="label" for="location">{{ $t('event_form.location') }}</label>
                        <input id="location" v-model="location" type="text" />
                    </div>

                    <div class="field">
                        <label class="label" for="location">{{ $t('event_form.address') }}</label>
                        <input id="address" v-model="address" type="text" />
                    </div>

                    <div class="field">
                        <label class="label" for="location">{{ $t('event_form.google_maps_url') }}</label>
                        <input id="googleMapUrl" v-model="googleMapUrl" type="text" />
                    </div>

                    <div class="field">
                        <label class="label" for="image">{{ $t('event_form.picture') }}</label>
                        <div v-if="! image" class="image-uploader">
                            <input id="image" ref="image" accept="image/*" type="file" @change="previewImage">

                            <p>{{ $t('upload_component.add_files') }}</p>
                            <span>{{ $t('upload_component.or') }}</span>
                            <a class="btn btn--blue" href="#" @click.prevent="$refs.image.click()">
                                {{ $t('upload_component.add_image') }}
                            </a>
                        </div>

                        <div v-if="image" class="image-preview">
                            <img v-if="image.includes('base64')" :src="image">
                            <img v-else :src="'/files/events/' + image" />
                            <a href="#" @click.prevent="removeImage">{{ $t('upload_component.delete_image') }}</a>
                        </div>
                    </div>

                    <div class="field">
                        <label class="label" for="description">{{ $t('event_form.description') }}</label>
                        <textarea id="description" v-model="description"></textarea>
                    </div>

                    <label for="divisions">{{ $t('event_form.divisions') }}</label>
                    <vue-multiselect
                        id="divisions"
                        v-model="selectedBusinessUnits"
                        :close-on-select="false"
                        :multiple="true"
                        :options="businessUnits"
                        :searchable="false"
                        class="multiselect--push-bottom"
                        deselectLabel=""
                        label="name"
                        selectLabel=""
                        selectedLabel=""
                        track-by="id">
                        <template slot="tag" slot-scope="props">
                            <span v-text="props.option.name + ', '"></span>
                        </template>
                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">{{ props.option.name }}</span>
                                <img alt="check" class="selected" src="/svg/check.svg">
                            </div>
                        </template>
                        <span slot="noResult">{{ $t('event_form.no_results') }}</span>
                    </vue-multiselect>

                    <label for="functions">{{ $t('event_form.function') }}</label>
                    <vue-multiselect
                        id="functions"
                        v-model="selectedEmployeeFunctions"
                        :close-on-select="false"
                        :multiple="true"
                        :options="employeeFunctions"
                        :searchable="false"
                        class="multiselect--push-bottom"
                        deselectLabel=""
                        label="name"
                        selectLabel=""
                        selectedLabel=""
                        track-by="id">
                        <template slot="tag" slot-scope="props">
                            <span v-text="props.option.name + ', '"></span>
                        </template>
                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">{{ props.option.name }}</span>
                                <img alt="check" class="selected" src="/svg/check.svg">
                            </div>
                        </template>
                        <span slot="noResult">{{ $t('event_form.no_results') }}</span>
                    </vue-multiselect>

                    <div class="field">
                        <label class="label" for="notificationText">{{
                                $t('event_form.text_push_notification')
                                                                    }}</label>
                        <input id="notificationText" v-model="notificationText" type="text" />
                        <a v-if="$route.params.id" class="btn btn--link" href="#" @click.prevent="sendPushNotification">
                            {{ $t('event_form.send_notification') }}
                            <span>&gt;</span>
                        </a>
                    </div>

                    <div class="field">
                        <label class="label" for="isFeatured">{{ $t('event_form.is_featured') }}</label>
                        <input id="isFeatured" v-model="isFeatured" type="checkbox" />
                    </div>
                </div>
            </div>

            <div v-if="$route.params.id" class="content-wrap__sidebar content-wrap__sidebar--sticky">
                <div class="content-wrap__sidebar__title">{{ $t('event_form.present') }} <span v-if="attenders.length">({{
                        attenders.length
                                                                                                                       }} {{
                        $t('event_form.to_go')
                                                                                                                       }})</span>
                </div>

                <ul>
                    <li v-for="attender in attenders" :key="'attender_' + attender.id">{{ attender.name }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import VueMultiselect from "vue-multiselect/src/Multiselect";
import Datepicker from "vuejs-datepicker";
import moment from 'moment';
import { BusinessUnitService } from "../../services";

export default {
    name: 'events-form',

    components: { VueMultiselect, Datepicker },

    beforeRouteEnter(to, from, next) {
        window.axios.get('/languages').then(response => {
            let languages = response.data
            let language = window.authUser.language_relation || languages.find(language => {
                return language.default === 1;
            })

            const eventId = to.params.id;
            if (eventId) {
                window.axios.get(`/events/${eventId}`).then((response) => {
                    const event = response.data;

                    const translations = event.translations.map(t => {
                        return {
                            title: t.title,
                            description: t.description,
                            image: t.image,
                            notificationText: t.notification_text,
                            language: t.language,
                        }
                    });
                    next(vm => {
                        vm.translations = translations;
                        vm.title = translations[0].title;
                        vm.dateFrom = event.date_from;
                        vm.timeFrom = moment(event.date_from).format('HH:mm');
                        vm.dateTo = event.date_to;
                        vm.timeTo = moment(event.date_to).format('HH:mm');
                        vm.location = event.location;
                        vm.address = event.address;
                        vm.googleMapUrl = event.google_map_url;
                        vm.image = translations[0].image;
                        vm.notificationText = translations[0].notificationText;
                        vm.description = translations[0].description;
                        vm.isFeatured = event.is_featured;
                        vm.selectedBusinessUnits = event.business_units;
                        vm.selectedEmployeeFunctions = event.employee_functions;
                        vm.attenders = event.users;
                        vm.language = language;
                        vm.languages = languages;
                    });
                }).catch((err) => {
                    next({ name: 'events.index' });
                });
                return;
            }
            next(vm => {
                vm.language = language;
                vm.languages = languages;
            });
        });
    },

    data() {
        return {
            businessUnits: [],
            employeeFunctions: [],

            languages: [],

            langSwitcherOptions: {
                placeholder: this.$t('event_form.select_language'),
                label: "name",
                'track-by': "code",
                selectLabel: "",
                selectedLabel: "",
                deselectLabel: ""
            },

            language: null,

            translations: [],

            title: null,
            dateFrom: null,
            timeFrom: null,
            dateTo: null,
            timeTo: null,
            location: null,
            address: null,
            googleMapUrl: null,
            image: null,
            description: null,
            selectedBusinessUnits: [],
            selectedEmployeeFunctions: [],
            notificationText: null,
            isFeatured: false,
            attenders: [],
        };
    },

    watch: {
        language(newVal, oldVal) {
            if (!oldVal) {
                return;
            }

            this.cacheTranslatableFields(oldVal.code);
            this.getTranslatableFields(newVal.code);
        }
    },

    beforeMount() {
        this.getBusinessUnits().then(() => {
            this.selectedBusinessUnits = this.selectedBusinessUnits.filter((d) => {
                return !!this.businessUnits.find(b => b.id === d.id);
            });
        });

        this.getEmployeeFunctions();

        // Set dutch as default language if its create route
        if (!this.$route.params.id) {
            this.language = this.languages[0];
        }
    },

    methods: {
        getBusinessUnits() {
            return BusinessUnitService.fetchByPermission(this.$permissions.ADD_AND_EDIT_EVENTS).then((response) => {
                this.businessUnits = response.data;

                return response;
            });
        },

        getEmployeeFunctions() {
            window.axios.get('/employee-functions').then((response) => {
                this.employeeFunctions = response.data
            });
        },

        cacheTranslatableFields(lang) {
            const translation = this.translations.find(t => t.language === lang);

            const translationData = {
                title: this.title,
                description: this.description,
                image: this.image,
                notificationText: this.notificationText,
                language: lang,
            };

            if (!translation) {
                this.translations.push(translationData);

                return;
            }

            translation.title = translationData.title;
            translation.description = translationData.description;
            translation.image = translationData.image;
            translation.notificationText = translationData.notificationText;
        },

        getTranslatableFields(lang) {
            const translation = this.translations.find(t => t.language === lang);

            if (!translation) {
                this.title = null;
                this.description = null;
                this.image = null;
                this.notificationText = null;

                return;
            }

            this.title = translation.title;
            this.description = translation.description;
            this.image = translation.image;
            this.notificationText = translation.notificationText;
        },

        previewImage(event) {
            let input = event.target;

            if (input.files && input.files[0]) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    if (e.total > 22000000) {
                        this.$flashMessage(this.$t('upload_component.image_to_large'), 5000);
                    }

                    this.image = e.target.result;
                };

                reader.readAsDataURL(input.files[0]);
            }
        },

        removeImage() {
            this.image = null;
        },

        save() {
            const eventId = this.$route.params.id;

            // In case a new event is created, and language watcher is not fired,
            // make sure to "cache" the translations array
            this.cacheTranslatableFields(this.language.code);

            const params = {
                businessUnits: this.selectedBusinessUnits.map(b => b.id),
                employeeFunctions: this.selectedEmployeeFunctions.map(b => b.id),
                dateFrom: this.dateFrom ? moment(this.dateFrom).format('YYYY-MM-DD') + ' ' + this.timeFrom : null,
                dateTo: this.dateTo ? moment(this.dateTo).format('YYYY-MM-DD') + ' ' + this.timeTo : null,
                location: this.location,
                address: this.address,
                googleMapUrl: this.googleMapUrl,
                isFeatured: this.isFeatured,
                translations: this.getFilteredTranslations(),
            };

            if (eventId) {
                this.update(eventId, params);
                return;
            }

            this.store(params);
        },

        getFilteredTranslations() {
            return this.translations.filter(t => {
                return t.title || t.description || t.image || t.notificationText;
            });
        },

        store(params) {
            window.axios.post('/events', params).then((response) => {
                this.$flashMessage(this.$t('event_form.event_saved'), 5000, 'succes');
                this.$router.push({ name: 'events.edit', params: { id: response.data.id } });
            }).catch((err) => {
                const errors = Object.entries(err.response.data.errors).map((err) => err[1]).flat().join('<br>');
                this.$flashMessage(errors, 5000, 'error');
            });
        },

        update(eventId, params) {
            window.axios.put(`/events/${eventId}`, params).then((response) => {
                this.$flashMessage(this.$t('event_form.event_saved'), 5000, 'succes');
            }).catch((err) => {
                const errors = Object.entries(err.response.data.errors).map((err) => err[1]).flat().join('<br>');
                this.$flashMessage(errors, 5000, 'error')
            });
        },

        sendPushNotification() {
            if (!this.notificationText) {
                return;
            }

            const eventId = this.$route.params.id;

            const params = {
                language: this.language.code,
                notificationText: this.notificationText,
            };

            window.axios.post(`/events/${eventId}/send-push-notification`, params)
                .then((response) => {
                    this.$flashMessage(this.$t('event_form.event_notification_send'), 5000, 'succes');
                });
        }
    },
}
</script>
