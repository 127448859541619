export default {
    general: {
        yes: 'jawohl',
        no: 'nein',
    },
    navigation: {
        instructions: 'Fahrerhandbuch',
        news: 'Nachrichten',
        documents: 'Dokumente',
        users: 'Benutzer',
        contacts: 'Kontakte',
        events: 'Ereignisse',
        statistics: 'Statistik',
        push_notification: 'Push-Benachrichtigung',
        ideabox: 'Vorschlagsbox',
        business_units: 'Divisionen',
        locations_on_route: 'Standorte unterwegs',
        furloughs: 'Urlaub',
        roles: 'Rollen',
        log_out: 'Abmelden',
        language_switch: {
            title: 'Sprache',
            languages: {
                nl: 'Niederlandisch',
                en: 'Englisch',
                de: 'Deutsch',
            },
        },
    },
    locations_on_route: {
        data: 'daten',
        locations_on_route: 'Standorte unterwegs',
        select_language: 'Sprache auswählen',
        add_location: 'Ort hinzufügen',
        title: 'Titel',
        division: 'Aufteilung',
        divisions: 'Divisionen',
        change_location: 'den Ort wechseln',
        save_changes: 'Änderungen speichern',
        add_new_location: 'Neuen Standort hinzufügen',
        select_division: 'Division auswählen',
        no_results: 'keine Ergebnisse',
        button_text: 'Schaltflächentext',
        button_url: 'Schaltflächen-URL',
        description: 'Bezeichnung',
        location_saved: 'Standort gespeichert',
        delete: 'Löschen',
        location_delete_failed: 'Fehler beim Löschen, bitte versuchen Sie es später noch einmal',
    },
    ab_component: {
        component_delete: 'Komponente löschen',
        title_big: 'Titel Groß',
        title_small: 'Titel klein',
        textbox: 'Textfeld',
        add_pdf: 'PDF hinzufügen',
        preview_pdf: 'Vorschau PDF',
        delete_pdf: 'PDF löschen',
        pdf_name: 'PDF name',
        or: 'oder',
    },
    upload_component: {
        add_files: 'Dateien hier ablegen',
        or: 'oder',
        add_image: 'Bild hinzufügen',
        add_pdf: 'PDF hinzufügen',
        delete_image: 'Bild löschen',
        image_too_large: 'Bilder hochladen, die kleiner als 22 MB sind',
    },
    article_app: {
        news_article: 'Nachrichten Artikel',
        tip_article: 'Tippartikel',
        preview: 'Vorschau',
        delete_article: 'Artikel löschen',
        publish: 'Veröffentlichen',
        to_concept: 'in Entwurf konvertieren',
        article_published_flash: 'Artikel veröffentlicht',
        article_duplicated_flash: 'Artikel wird dupliziert, dies kann eine Minute dauern.',
        article_concept_flash: 'Artikel in Entwurf konvertiert',
        article_title_image_missing: 'Titel oder Hauptbild wurde noch nicht eingegeben',
        article_select_language: 'Sprache auswählen',
        duplicate: 'Duplikat',
    },
    article_related_articles: {
        related_articles: 'Verwandte Artikel',
        article_one: 'Artikel 1',
        article_two: 'Artikel 2',
        article_three: 'Artikel 3',
        no_results: 'Keine Ergebnisse',
        select_article: 'Artikel auswählen',
    },
    article_tab_content: {
        general_info: 'Allgemeine Daten',
        title: 'Titel',
        primary_image: 'Primärbild',
        content: 'Inhalt',
        settings: 'Einstellungen',
        publish_at: 'Veröffentlichen um',
        select_date: 'Datum auswählen',
        article_success_flash: 'Erfolgreich gespeichert',
        image_to_large: 'Bilder hochladen, die kleiner als 22 MB sind',
    },
    article_tab_division: {
        divisions: 'Abteilungen',
        no_results: 'Keine Ergebnisse',
        function: 'Funktion',
        all_functions: 'Alle Funktionen',
        all_divisions: 'Alle Abteilungen',
        placeholder: 'Division auswählen',
    },
    article_tab_profile: {
        linked_profile: 'Verknüpftes Profil',
        title: 'Titel',
        description: 'Beschreibung',
        profile: 'Profil',
        select_profile: 'Profil auswählen',
        all_divisions: 'Alle Abteilungen',
        all_departments: 'Alle Abteilungen',
        all_functions: 'All Funktionen',
        placeholder: 'Division auswählen',
    },
    article_tabs: {
        content: 'Inhalt',
        story: 'Geschichte von',
        linked_profile: 'Verknüpftes Profil',
        related_articles: 'Verwandte Artikel',
        divisions: 'Abteilungen',
        push_notification: 'Push Nachrichten',
    },
    article_push_notification_tabs: {
        send: 'Senden',
        overview: 'Überblick',
        information: 'Information',
        push_notification_language: 'Push-Benachrichtigung Deutsch',
        no_push_notifications_yet: 'Zu diesem Artikel wurden keine Push-Benachrichtigungen gesendet.',
        date: 'Datum',
    },
    article_tab_story: {
        profile: 'Profil',
        select_profile: 'Profil auswählen',
        story_of: 'Geschichte von',
        all_divisions: 'Alle Abteilungen',
        all_departments: 'Alle Abteilungen',
    },
    component_list: {
        add_component: 'Komponente hinzufügen',
        title_big: 'Titel Groß',
        title: 'Titel klein',
        textbox: 'Textfeld',
        image: 'Bild',
        pdf: 'PDF',
        poll: 'Poll',
        saved_success_flash: 'Erfolgreich gespeichert',
    },
    delete_article_modal: {
        delete_article: 'Möchten Sie diesen Artikel löschen?',
        warning: 'Dies kann nicht rückgängig gemacht werden.',
        cancel: 'Abbrechen',
        delete: 'Löschen',
    },
    delete_chapter_modal: {
        delete_chapter: 'Kapitel löschen',
        warning: 'Möchten Sie dieses Kapitel wirklich löschen? Dies kann nicht rückgängig gemacht werden.',
        cancel: 'Abbrechen',
        delete: 'Löschen',
    },
    delete_component_modal: {
        delete_component: 'Möchten Sie diese Komponente entfernen?',
        warning: 'Dies kann nicht rückgängig gemacht werden.',
        cancel: 'Abbrechen',
        delete: 'Löschen',
    },
    delete_doc_modal: {
        delete_doc: 'Möchten Sie dieses Dokument löschen?',
        warning: 'Dies kann nicht rückgängig gemacht werden.',
        cancel: 'Abbrechen',
        delete: 'Löschen',
    },
    delete_map_modal: {
        delete_map: 'Möchten Sie dieses Dokument löschen?',
        warning: 'Dies kann nicht rückgängig gemacht werden.',
        cancel: 'Abbrechen',
        delete: 'Löschen',
    },
    delete_paragraph_modal: {
        delete_map: 'Möchten Sie diesen Absatz löschen?',
        warning: 'Dies kann nicht rückgängig gemacht werden.',
        cancel: 'Abbrechen',
        delete: 'Löschen',
    },
    delete_sub_paragraph_modal: {
        delete_sub_paragraph: 'Möchten Sie diesen Unterabschnitt löschen?',
        warning: 'Dies kann nicht rückgängig gemacht werden.',
        cancel: 'Abbrechen',
        delete: 'Löschen',
    },
    no_language_modal: {
        notification: 'Sprach Änderungen nicht möglich',
        argument: 'Es ist keine Sprache ausgewählt',
        ok: 'OK',
    },
    documents_add_doc: {
        add_new_document: 'Neues Dokument hinzufügen',
        add_document: 'Dokument hinzufügen',
        data: 'Daten',
        title: 'Titel',
        file: 'Datei',
        drag_file: 'Datei hierher ziehen',
        or: 'oder',
        add_file: 'Datei anhängen',
        delete_file: 'Datei löschen',
        replace_file: 'Datei ersetzen',
    },
    documents_add_map: {
        add_new_map: 'Neuen Ordner hinzufügen',
        add_map: 'Ordner hinzufügen',
        data: 'Daten',
        title: 'Titel',
        divisions: 'Abteilungen',
        no_results: 'Keine Ergebnisse',
        function: 'Funktion',
        language: 'Sprache',
        select_language: 'Sprache auswählen',
        select_function: 'Select funktion',
        all_divisions: 'Alle Abteilungen',
        select_map: 'Ordner auswählen',
    },
    documents_add_link: {
        add_link: 'Link hinzufügen',
        data: 'Daten',
        link: 'Link',
        title: 'Titel',
        example_url: 'z.B. https://www.abtexelgroup.com/flotte',
    },
    documents_app: {
        add_document: 'Dokument hinzufügen',
        add_folder: 'Ordner hinzufügen',
        add_link: 'Link hinzufügen',
        title: 'Titel',
        last_modified: 'Zuletzt geändert',
        search_placeholder: 'Suche...',
        no_searchresults: 'Keine Suchergebnisse',
    },
    documents_edit_doc: {
        edit_doc: 'Dokument bearbeiten',
        delete_doc: 'Dokument löschen',
        save_changes: 'Änderungen speichern',
        data: 'Daten',
        title: 'Titel',
        file: 'Datei',
        drag_file: 'Datei hierher ziehen',
        or: 'oder',
        add_file: 'Datei anhängen',
        delete_file: 'Datei löschen',
        replace_file: 'Datei ersetzen',
        preview_pdf: 'Vorschau PDF',
        image_to_large: 'Bilder hochladen, die kleiner als 22 MB sind',
    },
    documents_edit_link: {
        edit_link: 'Link bearbeiten',
        delete_link: 'Link löschen',
        save_changes: 'Änderungen speichern',
        data: 'Daten',
        title: 'Titel',
    },
    documents_edit_map: {
        edit_folder: 'Ordner bearbeiten',
        delete_folder: 'Ordner löschen',
        save_changes: 'Änderungen speichern',
        data: 'Daten',
        title: 'Titel',
        divisions: 'Abteilungen',
        no_results: 'Keine Ergebnisse',
        select_language: 'Sprache auswählen',
        language: 'Srpache',
        all_functions: 'Alle Funktionen',
        all_divisions: 'Alle Abteilungen',
        function: 'Funktion',
    },
    manual_app: {
        deadline: 'Bitte wählen Sie eine Frist',
    },
    manual_chapter: {
        delete_chapter: 'Kapitel löschen',
        preview: 'Vorschau',
        add_paragraph: 'Absatz hinzufügen',
    },
    manual_component: {
        saved_success: 'Erfolgreich gespeichert',
    },
    manual_header: {
        instructions: 'Fahrerhandbuch',
        basic_manual: 'Basic manual',
        business_unit_specific: 'Geschäftsbereichs Spezifisch',
        function_specific: 'Funktionsspezifisch',
        publish: 'Veröffentlichen',
        select_language: 'Sprache auswählen',
        version: 'Version',
    },
    manual_google_translate: {
        started: 'Übersetzung hat begonnen',
        translate_to_english: 'Ins Englische übersetzen',
        automatic_translation: 'Automatische Übersetzung',
        automatic_translation_warning: 'Möchten Sie diese Anleitung wirklich ins Englische übersetzen? Der aktuelle Inhalt für diese Sprache wird überschrieben. Das Generieren der Übersetzung kann einige Minuten dauern.',
        translate: 'Übersetzen',
    },
    manual_paragraph: {
        delete_paragraph: 'Absatz löschen',
        add_subsection: 'Unterabschnitt hinzufügen',
    },
    manual_subsection: {
        delete_subsection: 'Unterabschnitt löschen',
    },
    manual_tab_adjustments: {
        delete_subsection: 'Unterabschnitt löschen',
        settings: 'Einstellungen',
        adjustments: 'Anpassungen',
        deadline: 'Frist vereinbaren',
        pick_date: 'Wähle ein Datum',
        contactperson: 'Ansprechpartner',
    },
    manual_tab_confirmed: {
        confirmed: 'Zustimmen',
        not_confirmed: 'Nicht vereinbart',
        show_all: 'Alle anzeigen',
        title: 'Titel',
        type: 'Typ',
        date_agree: 'Datum vereinbart',
        phone: 'Telefonnummer',
        no_results: 'Keine Ergebnisse',
        business_unit: 'Aufteilung',
    },
    manual_tab_content: {
        add_chapter: 'Kapitel hinzufügen',
        search: 'Suche...',
        chapters: 'Kapitel',
    },
    manual_tab_divisions: {
        divisions: 'Abteilungen',
        no_results: 'Keine Ergebnisse',
        function: 'Funktion',
        all_divisions: 'Alle Abteilungen',
    },
    manual_tabs: {
        content: 'Inhalt',
        adjustments: 'Anpassungen',
        agree: 'zustimmen',
        divisions: 'Abteilungen',
    },
    new_chapter_modal: {
        add_chapter: 'Kapitel hinzufügen',
        chapter_name: 'Kapitelname',
    },
    new_paragraph_modal: {
        add_paragraph: 'Absatz hinzufügen',
        name_paragraph: 'Absatzname',
    },
    news_app: {
        news: 'Nachrichten',
        tip_article: 'Tippartikel hinzufügen',
        add_article: 'Nachrichtenartikel hinzufügen',
        search: 'Suche...',
        title: 'Titel',
        type: 'Typ',
        last_edited: 'Zuletzt bearbeitet',
        planned_publication: 'Geplante Veröffentlichung',
        posted: 'Gepostet',
        no_results: 'Keine Suchergebnisse',
    },
    new_subsection_modal: {
        add_subsection: 'Unterabschnitt hinzufügen',
        name_subsection: 'Unterabschnitt Name',
    },
    statistics_app: {
        statistics: 'Statistik',
        login: 'Einloggen',
        news: 'Nachrichten',
        fuelReports: 'Kraftstoffberichte',
    },
    statistics_app_fuel_reports: {
        reports_opened: 'Kraftstoffberichte geöffnet',
        opened_number: 'Anzahl der pro Monat geöffneten Zeiten',
        opened_number_second: 'Anzahl der geöffneten Zeiten',
        month: 'Monat',
    },
    statistics_app_logins: {
        login_number: 'Nummer wöchentlich angemeldet',
        week: 'Woche',
        login_number_second: 'Häufigkeit der Anmeldung',
        unique_login_number: 'Anzahl der wöchentlich eindeutig angemeldeten Personen',
    },
    statistics_app_news: {
        news_opened: 'Geöffnete Nachrichtenartikel',
        news_article: 'Nachrichtenartikel',
        opened: 'Anzahl der geöffneten',
        select_language: 'Sprache auswählen',
        published_at: 'Veröffentlichungsdatum',
    },
    switch_confirmation_modal: {
        save_changes: 'Änderungen speichern',
        save_confirmation: 'Möchten Sie diese Änderungen speichern?',
        yes: 'Ja',
        no: 'Nein',
    },
    article_tab_push_notifications: {
        push_notification: 'Push-Benachrichtigung',
        push_notification_dutch: 'Push notificatie Dutch',
        push_notification_polish: 'Push notificatie Polish',
        push_notification_english: 'Push notificatie English',
        send_notification: 'Benachrichtigung senden',
        message: 'Sie senden eine Push Nachrichten nur, wenn das Feld ausgefüllt ist. Beispiel:' +
            'Wenn nur das Feld für Niederländisch ausgefüllt ist, erhalten nur niederländische Benutzer eine Benachrichtigung',
        flash_message: 'Push Nachrichten',
    },
    add_chapter_link_modal: {
        select_modal: 'Manuelles auswählen',
        label: 'Etikett',
        manual: 'Handbuch',
        chapter: 'Kapitel',
        paragraph: 'Sektion',
        cancel: 'Abbrechen',
        insert: 'Einfügen',
    },
    manual_component_list: {
        add_component: 'Komponente hinzufügen',
        title_big: 'Großer Titel',
        title: 'Titel',
        textbox: 'Textfeld',
        image: 'Bild',
    },
    delete_contact_person_confirmation_modal: {
        h1: 'Kontaktperson entfernen?',
        paragraph: 'Dies kann nicht rückgängig gemacht werden.',
        no: 'Nein',
        yes: 'Ja',
    },
    contact_form: {
        has_whatsapp: 'Whatsapp',
        delete_contact: 'Kontakt löschen',
        change_contact: 'Kontakt bearbeiten',
        add_contact: 'Neuen Kontakt hinzufügen',
        data: 'Persönliche Informationen',
        divisions: 'Abteilungen',
        no_results: 'Keine Ergebnisse',
        department: 'Abteilung',
        title: 'Titel',
        subtitle: 'Untertitel',
        phone: 'Telefonnummer',
        mobile_phone: 'Handynummer',
        email: 'Email',
        language: 'Sprache',
        picture: 'Bild',
        contactpersons: 'Kontaktpersonen',
        add_contactperson: 'Zusätzliche Kontaktperson hinzufügen',
        select_language: 'Sprache auswählen',
        contactperson_saved: 'Kontaktperson gespeichert',
        contactperson_deleted: 'Kontaktperson gelöscht',
    },
    contact_person: {
        name: 'Name',
        picture: 'Bild',
        contactperson_deleted: 'Kontaktperson gelöscht',
        save_changes: 'Änderungen speichern',
        add_contact: 'Kontakt hinzufügen',
    },
    contact_index: {
        add_contact: 'Kontakt hinzufügen',
        title: 'Titel',
        subtitle: 'Undertitel',
        phone: 'Telefonnummer',
        email: 'Email',
        departments: 'Abteilungen',
        divisions: 'Abteilungen',
    },
    event_form: {
        change_event: 'Ereignis ändern',
        add_new_event: 'Neues Ereignis hinzufügen',
        add_event: 'Ereignis hinzufügen',
        save_changes: 'Änderungen speichern',
        data: 'Daten',
        title: 'Titel',
        date_of: 'Datum von',
        time_of: 'Zeit der',
        date_till: 'Datum bis',
        time_till: 'Zeit bis',
        location: 'Ort',
        address: 'Adresse',
        google_maps_url: 'Google maps URL',
        picture: 'Bild',
        description: 'Beschreibung',
        divisions: 'Aufteilungen',
        no_results: 'Keine Ergebnisse',
        function: 'Funktion',
        text_push_notification: 'Text-Push Nachrichten',
        send_notification: 'Benachrichtigung senden',
        is_featured: 'Wird vorgestellt',
        present: 'Gegenwart',
        to_go: 'gehen',
        event_saved: 'Ereignis gespeichert',
        event_notification_send: 'Ereignisbenachrichtigung gesendet',
        select_language: 'Sprache auswählen',
        pick_date: 'Datum auswählen',
    },
    events: {
        events: 'Veranstaltungen',
        add_event: 'Ereignis hinzufügen',
        title: ' Titel',
        date: 'Datum',
        location: 'Ort',
    },
    publish_manual_version_confirmation_modal: {
        publish: 'Handbuch veröffentlichen?',
        no: 'Nein',
        yes: 'Ja',
    },
    push_notifications: {
        notifications: 'Benachrichtigungen',
        pay_slip_notification: 'Lohnzettel Nachricht',
        wage_supplement_notification: 'Lohnzuschlagsbenachrichtigung',
        data: 'Daten',
        push_notification_dutch: 'Push Nachrichten Niederländisch',
        push_notification_polish: 'Push Nachrichten polnisch',
        push_notification_english: 'Push Nachrichten Englisch',
        push_notification_german: 'Push-Benachrichtigungen Deutsch',
        only_afas_users: 'Nur Push Nachricht senden an mitarbeiter mit AB Texel Vertrag',
        divisions: 'Abteilungen',
        no_results: 'Keine Ergebnisse',
        send_notification: 'Benachrichtigung senden',
        all_divisions: 'Alle Abteilungen',
        push_notification_send: 'Push Nachrichten gesendet',
        overview: 'Überblick',
        search: 'Suche',
        push_notificatie_dutch: 'Push-Benachrichtigungen Niederländisch',
        date: 'Datum',
        afas_users: 'Nur AFAS-Benutzer',
    },
    personal_document: {
        expiration_date: 'Haltbarkeitsdatum',
        delete: 'löschen',
        pick_a_date: 'Datum auswählen',
        front: 'Vorderseite',
        back: 'Zurück',
        document_upload: 'Dokument hochladen',
        certificates: 'Zertifikate',
        name_certificate: 'Zertifikatsname',
        add_certificate: 'Zertifikat hinzufügen',
        delete_document_type: 'Dokumenttyp löschen',
        add_type: 'Dokumenttyp hinzufügen',
    },
    users: {
        users: 'Benutzer',
        add_user: 'Benutzer hinzufügen',
        search: 'Suche...',
        name: 'Name',
        division: 'Abteilung',
        function: 'Funktion',
        phone: 'Telefonnummer',
        no_results: 'Keine Ergebnisse',
        not_available: 'Nicht verfügbar',
    },
    user_docs: {
        edit_user: 'Benutzer wechseln',
        add_user: 'Benutzer hinzufügen',
        general_data: 'Allgemeine Daten',
        documents: 'Dokumente',
        document_types: 'Dokumenttypen',
    },
    user_form: {
        general_data: 'Allgemeine Daten',
        documents: 'Dokumente',
        add_new_user: 'Neuen Benutzer hinzufügen',
        add_user: 'Benutzer hinzufügen',
        save_changes: 'Änderungen speichern',
        data: 'Daten',
        afas_number: 'AFAS number',
        name: 'Name',
        email: 'Email',
        divisions: 'Abteilungen',
        no_results: 'Keine Ergebnisse',
        function: 'Funktion',
        phone: 'Telefonnummer',
        street: 'Straße',
        number: 'Number',
        city: 'Stadt',
        country: 'Land',
        active_user: 'Aktiver Benutzer',
        is_administrator: 'Ist Administrator??',
        view_news_items: 'Nachrichten, Dokumente und Handbücher anzeigen',
        news_from_division: 'Nachrichten von Abteilung (en)',
        available_payslips: 'Verfügbare Gehaltsabrechnungen',
        no_payslips: 'Keine Gehaltsabrechnungen verfügbar',
        available_wage_supplements: 'Verfügbare Lohnzuschläge',
        no_wage_supplements: 'Keine Lohnzuschläge verfügbar',
        user_saved: 'Benutzer gespeichert',
        visible_anniversary: 'Jubiläen und In- und Außerbetriebnahme sichtbar',
        employee_number: 'Personalnummer',
    },
    ideabox: {
        header: 'Vorschlagsbox',
        search: 'Suche...',
        status: {
            open: 'offen',
            doing: 'ausstehend',
            done: 'behandelt',
        },
    },
    furloughs: {
        title: 'Urlaub',
        applicant: 'Antragsteller',
        from: 'Von',
        to: 'Bis',
        status: 'Status',
        statuses: {
            '1': 'hervorragend',
            '2': 'genehmigt',
            '3': 'abgelehnt',
        },
        detail: {
            title: 'Detail hinterlassen',
            data: 'Daten',
            explanation: 'Erklärung',
        },
    },
    business_units: {
        business_unit: 'Aufteilung',
        business_units: 'Abteilungen',
        business_unit_management: 'Abteilungen',
        saved: 'Erfolgreich geändert',
        permissions_label: 'App-Berechtigungen',
        emails_label: 'E-mailadressen',
        anniversary_item: 'Jubiläumsartikel',
        anniversaries_label: 'Jahrestage',
        add_anniversary: 'Zusätzlichen Jubiläumsartikel hinzufügen',
        permissions: {
            'tanklocaties-wasstraten': 'Tankstellen und Autowaschanlagen',
            furlough: 'Urlaub',
        },
    },
};
