<template>
    <div>
        <portal-target name="modals">
            <!--Teleport modals here-->
        </portal-target>
        <portal to="modals">
            <delete-doc-modal @close="showDeleteDocModal = false"
                              v-if="showDeleteDocModal" @confirm="deleteDoc" />
        </portal>
        <div class="content">
            <div class="header" id="n-header">
                <ul class="breadcrumbs">
                    <div v-for="breadcrumb in computedBreadcrumbs">
                        <a v-if="! Array.isArray(breadcrumb)" @click.prevent="gotoDirectory(breadcrumb.id)">{{ breadcrumb.name }}</a>
                        <a @click="togglePopover" v-else>
                            ...
                            <div class="popover-breads" :class="{ show : showBreadcrumbs }" v-click-outside="closePopOver">
                                <li v-for="subBreadcrumb in breadcrumb">
                                    <a @click.prevent="gotoDirectory(subBreadcrumb.id)">{{ subBreadcrumb.name }}</a>
                                </li>
                            </div>
                        </a>
                    </div>
                </ul>
                <div class="header-buttons" id="n-header-buttons">
                    <div>
                        <div @click="showDeleteDocModal = true" class="button light-blue">
                            <span>{{ $t('documents_edit_doc.delete_doc') }}</span>
                        </div>
                    </div>
                    <div>
                        <div class="button green">
                            <span @click="updateDocument">{{ $t('documents_edit_doc.save_changes') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-wrap">
                <div :class="{'content-wrap__content--push-right': !! $route.params.id}" class="content-wrap__content">
                    <div class="panel panel--push-top">
                        <h2 class="title title--push-bottom">{{ $t('documents_edit_doc.data') }}</h2>

                        <div class="field">
                            <label class="label" for="title">{{ $t('documents_edit_doc.title') }}</label>
                            <input id="title" type="text" v-model="title" />
                        </div>

                        <div class="field">
                            <label class="label" for="doc">{{ $t('documents_edit_doc.file') }}</label>
                            <div class="image-uploader" v-if="! doc && ! document">
                                <input @change="previewDoc" accept="application/pdf, image/*" id="doc" ref="doc" type="file">

                                <p>{{ $t('documents_edit_doc.drag_file') }}</p>
                                <span>{{ $t('documents_edit_doc.or') }}</span>
                                <a @click.prevent="$refs.doc.click()" class="btn btn--blue" href="#">
                                    {{ $t('documents_edit_doc.add_file') }}
                                </a>
                            </div>

                            <div class="image-preview" v-if="doc || document">
                                <img :src="doc" v-if="doc !== null && doc.includes('base64') && ! documentIsPdf">
                                <img :src="generatePreviewUrl()" v-else-if="! documentIsPdf" />
                                <a :href="'/documents/files/download/' + originalDocument.id" target="_blank" v-else-if="documentIsPdf && !isNewFile">
                                    {{ $t('documents_edit_doc.preview_pdf') }} ({{ filename }})
                                </a>
                                <a @click.prevent="removeDoc" href="#">{{ $t('documents_edit_doc.replace_file') }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DeleteDocModal from './DeleteDocModal';

    export default {
        name: 'documents-app',
        components: { DeleteDocModal },
        data() {
            return {
                title: null,
                doc: null,
                file: null,
                filename: null,
                showDeleteDocModal: false,
                document: null,
                originalDocument: null,
                documentIsPdf: false,
                isNewFile: false,
                breadcrumbs: [],
                showBreadcrumbs: false,
                parentId: null,
            };
        },

        beforeMount() {
            this.getData();
        },

        methods: {
            getData() {
                window.axios.get('/documents/files/' + this.$route.params.id).then((response) => {
                    this.document = response.data;
                    this.originalDocument = response.data;
                    this.title = response.data.name;
                    this.parentId = response.data.document_path_id;
                    this.getFolderStructure();
                });
            },
            previewDoc(event) {
                let input = event.target;

                if (input.files && input.files[0]) {
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        if (e.total > 22000000) {
                            this.$flashMessage(this.$t('documents_edit_doc.image_to_large'), 5000);
                        }

                        this.doc = e.target.result;
                        this.file = input.files[0];
                        this.filename = input.files[0].name;

                        this.documentIsPdf = input.files[0].type === 'application/pdf';
                        this.isNewFile = true;
                    };

                    reader.readAsDataURL(input.files[0]);
                }
            },

            updateDocument() {
                const formData = new FormData();
                formData.append('name', this.title);

                if (this.file) {
                    formData.append('data', this.file);
                    formData.append('filename', this.filename);
                }

                const requestOptions = {
                    'Content-Type': 'multipart/form-data',
                };

                window.axios.post(`/documents/files/update/${this.$route.params.id}`, formData, requestOptions)
                      .then((response) => {
                          if (response.data.document_path_id !== null) {
                              this.$router.push('/documenten/' + response.data.document_path_id);
                          } else {
                              this.$router.push('/documenten/');
                          }
                      })
                      .catch((err) => {
                          const errors = Object.entries(err.response.data.errors).map((err) => err[1]).flat().join('<br>');
                          this.$flashMessage(errors, 5000, 'error');
                      });
            },

            removeDoc() {
                this.doc = null;
                this.document = null;
                this.file = null;
                this.isNewFile = false;
            },

            deleteDoc() {
                window.axios.delete('/documents/files/delete/' + this.$route.params.id).then(response => {
                    if (response.data.parent_id !== null) {
                        this.$router.push('/documenten/' + response.data.parent_id);
                    } else {
                        this.$router.push('/documenten/');
                    }
                });
            },

            getFolderStructure() {
                window.axios.get('/documents/path/' + this.parentId).then(response => {
                    this.breadcrumbs = response.data.breadcrumbs;
                });
            },
            togglePopover: function () {
                this.showBreadcrumbs = !this.showBreadcrumbs;
                // some code to filter users
            },
            closePopOver() {
                this.showBreadcrumbs = false;
            },

            gotoDirectory(id) {
                if (id === null) {
                    return;
                }

                this.$router.push(`/documenten/${id}`);
            },

            generatePreviewUrl() {
                return '/documents/files/download/' + this.originalDocument.id + '?' + (new Date()).getTime();
            },

        },

        computed: {
            computedBreadcrumbs() {
                const breadcrumbs = this.breadcrumbs.slice().reverse();
                breadcrumbs.push({
                    id: null,
                    name: this.$t('documents_edit_doc.edit_doc'),
                });

                if (breadcrumbs.length <= 3) {
                    return breadcrumbs;
                }

                return [
                    ...breadcrumbs.slice(0, 2),
                    ...[breadcrumbs.slice(2, breadcrumbs.length - 1)],
                    ...breadcrumbs.slice(-1),
                ];
            },
        },
    };
</script>
