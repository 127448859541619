<template>
    <div id="header" class="manual-header">
        <div v-if="showManuals === true"
             class="overlay"
             @click="showManuals = false" />
        <div v-if="showVersions === true"
             class="overlay"
             @click="showVersions = false" />
        <div class="header-container">
            <div id="container">
                <div class="title-container">
                    <h1>{{ $t('manual_header.instructions') }}</h1>

                    <h1 class="title" @click.stop="showManuals = true">
                        {{ activeManualCopy.name }}
                        <img src="/img/caret-down-blue.svg">
                    </h1>


                    <div :class="{'show': showManuals}" class="manuals">
                        <span v-if="manuals.baseManuals.length" class="title">{{ $t('manual_header.basic_manual') }}</span>
                        <a v-for="manual of manuals.baseManuals"
                           :class="{'active': activeManualCopy.id === manual.id}"
                           @click="onManualSwitch(manual)">
                            {{ manual.name }}
                        </a>

                        <span v-if="businessUnitManuals.length"
                              class="title">{{ $t('manual_header.business_unit_specific') }}</span>
                        <a v-for="manual of businessUnitManuals"
                           :class="{'active': activeManualCopy.id === manual.id}"
                           @click="onManualSwitch(manual)">
                            {{ manual.name }}
                        </a>

                        <span v-if="manuals.employeeFunctionManuals.length"
                              class="title">{{ $t('manual_header.function_specific') }}</span>
                        <a v-for="manual of manuals.employeeFunctionManuals"
                           :class="{'active': activeManualCopy.id === manual.id}"
                           @click="onManualSwitch(manual)">
                            {{ manual.name }}
                        </a>
                    </div>
                </div>

                <span class="version" @click="showVersions = !showVersions">versie {{ manualVersion.version }} <img
                    src="/img/caret-down-green.svg"></span>
                <div :class="{'show':showVersions}" class="versions">
                    <a v-for="version in manualVersion.versions"
                       :class="{'active': manualVersion.version === version.version}"
                       @click="changeVersion(version.version)">
                        {{ $t('manual_header.version') + version.version }}
                    </a>
                </div>
            </div>
            <div id="buttons" class="buttons">
                <div class="language-select header">
                    <vue-multiselect
                        id="languages"
                        v-model="language"
                        v-bind="translations"
                        :allow-empty="false"
                        :options="languages"
                        :searchable="false"
                        @close="changeLanguage">
                        <template slot="singleLabel" slot-scope="props">
                            <img :src="'/svg/' + props.option.code + '.svg'" alt="country flag">
                            <span v-text="props.option.name"></span>
                        </template>
                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <img :src="'/svg/' + props.option.code + '.svg'" alt="country flag">
                                <span class="option__title">{{ props.option.name }}</span>
                            </div>
                        </template>
                    </vue-multiselect>
                </div>
                <div>
                    <div class="button green" v-if="!versionDisabled && !manualVersion.manual.is_publishing">
                            <span
                                @click="confirmPublishManualModal = true">
                                    {{ $t('manual_header.publish') }}
                            </span>
                        <div class="dropdown-button" @click="showPublishDropdown = !showPublishDropdown">
                            <img alt="collapse" src="/svg/collapse-arrow-white.svg">
                        </div>
                    </div>
                    <div v-if="showPublishDropdown === true" class="dropdown-list">
                        <div class="dropdown-item" @click="googleTranslateToEnglish = true">
                            {{ $t('manual_google_translate.translate_to_english') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <portal to="modals">
            <switch-manual-confirmation-modal v-if="confirmSwitchManual"
                                              @close="confirmSwitchManual = false"
                                              @switch-manual="onManualSwitchConfirm" />

            <publish-manual-version-confirmation-modal v-if="confirmPublishManualModal"
                                                       @close="confirmPublishManualModal = false"
                                                       @confirm="publish" />

            <google-translate-modal :language="language"
                                    @close="googleTranslateToEnglish = false"
                                    @confirm="translateToEnglish"
                                    v-if="googleTranslateToEnglish" />
        </portal>
    </div>
</template>

<script>
    import VueMultiselect from 'vue-multiselect/src/Multiselect';
    import GoogleTranslateModal from './Manuals/Modals/GoogleTranslateModal';
    import PublishManualVersionConfirmationModal from './Manuals/Modals/PublishManualVersionConfirmationModal'
    import SwitchManualConfirmationModal from './Manuals/Modals/SwitchManualConfirmationModal'

    export default {
        name: 'manual-header',

        components: {
            VueMultiselect,
            GoogleTranslateModal,
            PublishManualVersionConfirmationModal,
            SwitchManualConfirmationModal
        },

        props: {
            languages: {
                type: Array,
                required: true,
            },
            activeManual: {
                type: Object,
                required: true,
            },
            versionDisabled: {
                required: true,
            },
            manuals: {
                type: Object,
                required: true,
            },
            manualVersion: {
                required: true,
            },
        },

        data() {
            return {
                activeManualCopy: this.activeManual,
                confirmSwitchManual: false,
                confirmPublishManualModal: false,
                showPublishDropdown: false,
                googleTranslateToEnglish: null,
                showManuals: false,
                showVersions: false,
                sticky: null,
                translations: {
                    placeholder: this.$t('manual_header.select_language'),
                    label: 'name',
                    'track-by': 'code',
                    selectLabel: '',
                    selectedLabel: '',
                    deselectLabel: '',
                },
                language: {},
            };
        },

        computed: {
            businessUnitManuals() {
                const manuals = this.manuals.businessUnitManuals;

                let sorted = manuals.sort((a, b) => a.id - b.id);
                let sortedCopy = sorted.slice();

                sorted.forEach((item, index) => {
                    if (!item.business_unit.business_unit_id) {
                        return;
                    }

                    const parentIndex = sortedCopy.findIndex(i => i.business_unit_id === item.business_unit.business_unit_id);
                    sortedCopy.splice(parentIndex + 1, 0, item);
                    sortedCopy.splice(index + 1, 1);
                });

                return sortedCopy;
            },
        },

        beforeMount() {
            // this.getLanguages();
        },

        mounted() {
            this.language = this.$store.getters['chapters/language'];
            document.addEventListener('scroll', (event) => {
                let element = document.getElementById('header');
                let container = document.getElementById('container');
                let buttons = document.getElementById('buttons');
                if (element) {
                    if (element.getBoundingClientRect().y === 0) {
                        element.style.boxShadow = '0px 4px 12px rgba(0, 0, 0, 0.1)';
                        element.style.background = '#F9FAFB';
                        element.style.width = 'calc(100vw - 280px)';
                        element.style.transform = 'translateX(-80px)';
                        element.style.padding = '6px 0';
                        container.style.marginLeft = '80px';
                        container.style.marginTop = '10px';
                        container.style.marginBottom = '10px';
                        buttons.style.marginRight = '95px';
                    } else {
                        element.style.boxShadow = null;
                        element.style.background = null;
                        element.style.width = null;
                        element.style.transform = null;
                        element.style.padding = null;
                        container.style.marginLeft = null;
                        container.style.marginTop = null;
                        container.style.marginBottom = null;
                        buttons.style.marginRight = null;
                    }
                }
            });
        },

        methods: {
            getLanguages() {
                window.axios.get('/languages').then(response => {
                    this.languages = response.data;
                });
            },
            translateToEnglish() {
                this.$emit('translate-to-english');

                this.googleTranslateToEnglish = false;
            },
            changeLanguage() {
                this.$emit('change-language', this.language);
            },
            changeVersion(version) {
                if (this.manualVersion.version !== version) {
                    this.$emit('change-version', version);
                }
                this.showVersions = false;
            },
            onManualSave() {
                this.$emit('manual-save');
            },
            publish() {
                this.$emit('publish');
                this.confirmPublishManualModal = false;
            },
            onManualSwitch(manual) {
                if (manual.id !== this.activeManualCopy.id) {
                    this.activeManualCopy = manual;

                    if (this.$store.state.trackChanges.hasChanges) {
                        this.confirmSwitchManual = true;
                    } else {
                        this.$emit('manual-switch', this.activeManualCopy);
                    }

                    this.showManuals = false;
                }
            },

            onManualSwitchConfirm(save) {
                this.confirmSwitchManual = false;

                if (save) {
                    this.onManualSave();
                }

                this.$emit('manual-switch', this.activeManualCopy);
            },
        },
};
</script>

<style lang="scss">
    .language-activator {
        position: relative;
        top: 5px;
        right: 10px;
    }
</style>