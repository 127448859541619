import Vue from 'vue';
import Vuex from 'vuex';

import chapters from './chapters';
import articles from './articles';
import adjustments from './adjustments';
import trackChanges from './track-changes';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        chapters,
        articles,
        adjustments,
        trackChanges,
    },
});
