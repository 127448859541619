<template>
    <div>
        <div class="panel panel--push-top">
            <article-push-notification-tabs :article="article" />

            <div class="body">
                <table v-if="this.notifications.length > 0">
                    <thead>
                        <tr>
                            <th class="sortable">{{ $t('article_push_notification_tabs.push_notification_language') }}</th>
                            <th class="sortable">{{ $t('article_push_notification_tabs.date') }}</th>
                        </tr>
                    </thead>

                    <tbody ref="tableBody">
                        <tr v-for="(message, index) in this.notifications" :key="index">
                            <td>{{ message.message }}</td>
                            <td>{{ message.date | toFormattedDate('dd. DD MMMM YYYY') | upperCaseFirst }}</td>
                        </tr>
                    </tbody>
                </table>

                <h6 class="no-notifications" v-if="this.notifications.length === 0">{{ $t('article_push_notification_tabs.no_push_notifications_yet') }}</h6>
            </div>
        </div>
    </div>
</template>

<script>
    import VueMultiselect from 'vue-multiselect/src/Multiselect';
    import ArticlePushNotificationTabs from '../ArticlePushNotificationsTabs';

    export default {
        name: 'article-tab-push-notifications-overview',

        components: {
            ArticlePushNotificationTabs,
            VueMultiselect,
        },

        props: {
            article: {
                required: true,
            },
        },

        data() {
            return {
                notifications: [],
            };
        },

        methods: {
            getPushNotifications() {
                window.axios.get(`/articles/` + this.$route.params.id + `/push-notifications`)
                      .then((response) => {
                          this.notifications = response.data;
                      });
            },
        },

        mounted() {
            this.getPushNotifications();
        },
    };
</script>

<style lang="scss" scoped>
    .panel {
        padding : 0;

        .body {
            margin  : 0;
            padding : 0px 16px 56px 16px;

            .no-notifications {
                padding : 40px 16px 56px 16px;
            }
        }
    }
</style>
