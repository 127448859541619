import BusinessUnitsIndex from "../components/BusinessUnits/index";
import BusinessUnitsEdit from "../components/BusinessUnits/edit";
import PermissionsTab from "../components/BusinessUnits/Tabs/PermissionsTab";
import EmailsTab from "../components/BusinessUnits/Tabs/EmailsTab";
import AnniversariesTab from "../components/BusinessUnits/Tabs/AnniversariesTab";

export default [
    {
        path: '/divisies',
        component: BusinessUnitsIndex,
        name: 'businessUnitsManagement.index',
        meta: {
            permissions: ['edit-divisions']
        },
    },
    {
        path: '/divisies/:id',
        component: BusinessUnitsEdit,
        meta: {
            permissions: ['edit-divisions']
        },
        children: [
            {
                path: '',
                name: 'businessUnitsManagement.edit',
                redirect: 'permissions',
            },
            {
                path: 'permissions',
                name: 'permissions',
                component: PermissionsTab,
            },
            {
                path: 'emails',
                name: 'emails',
                component: EmailsTab,
            },
            {
                path: 'anniversaries',
                name: 'anniversaries',
                component: AnniversariesTab,
            },
        ],
    },
];
