<template>
    <div class="content">
        <div class="header">
            <h1>Contacten</h1>

            <div class="header-buttons">
                <div @click="createContact">
                    <div class="button green">
                        <span>{{ $t('contact_index.add_contact') }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="body">
            <div class="search">
                <label for="search">
                    <img alt="search" src="img/search.svg">
                </label>

                <input id="search" :placeholder="$t('users.search')" type="text" v-model="search" @keyup="onSearch" />
            </div>

            <table>
                <thead>
                    <tr>
                        <th :class="{'sorted': orderByColumn === 'title'}"  @click="orderBy('title')">{{ $t('contact_index.title') }}</th>
                        <th :class="{'sorted': orderByColumn === 'sub_title'}" @click="orderBy('sub_title')">{{ $t('contact_index.subtitle') }}</th>
                        <th :class="{'sorted': orderByColumn === 'phone'}" @click="orderBy('phone')">{{ $t('contact_index.phone') }}</th>
                        <th :class="{'sorted': orderByColumn === 'email'}" @click="orderBy('email')">{{ $t('contact_index.email') }}</th>
                        <th :class="{'sorted': orderByColumn === 'departments.name'}" @click="orderBy('departments.name')">{{ $t('contact_index.departments') }}</th>
                        <th>{{ $t('contact_index.divisions') }}</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="contact in contacts" :key="'contact_' + contact.id" @click="editContact(contact)">
                        <td>{{ contact.title }}</td>
                        <td>{{ contact.sub_title }}</td>
                        <td>{{ contact.phone }}</td>
                        <td>{{ contact.email }}</td>
                        <td>{{ contact.departments.map(f => f.name).join(', ') }}</td>
                        <td>{{ contact.business_units.map(b => b.name).join(', ') }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { debounce } from 'lodash';

    export default {
        name: 'contacts-index',

        data() {
            return {
                contacts: [],
                search: '',
                orderByColumn: null,
                orderByDirection: 'asc',
            };
        },

        beforeMount() {
            this.fetchContacts();
        },

        methods: {
            onSearch: debounce(function () {
                this.fetchContacts();
            }, 500),

            createContact() {
                this.$router.push({ name: 'contacts.create' });
            },

            editContact(contact) {
                this.$router.push({ name: 'contacts.edit', params: { id: contact.id } });
            },

            fetchContacts() {
                const promise = window.axios.get('/contacts', {
                    params: {
                        ...(this.search) && { search: this.search },
                        ...(this.orderByColumn) && { orderBy: this.orderByColumn },
                        ...(this.orderByColumn) && { orderByDirection: this.orderByDirection },
                    }
                });

                promise.then((response) => {
                    this.contacts = response.data;
                });
            },

            orderBy(column) {
                if (column === this.orderByColumn) {
                    this.orderByDirection = this.orderByDirection === 'asc' ? 'desc' : 'asc';
                    this.fetchContacts();
                    return;
                }

                this.orderByColumn = column;
                this.orderByDirection = 'asc';
                this.fetchContacts();
            }
        },
    }
</script>
