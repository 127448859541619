<template>
    <div class="content">
        <div>
            <div class="header not-sticky">
                <h1>{{ $route.params.id ? $t('user_form.edit_user') : $t('user_form.add_new_user') }}</h1>

                <div class="header-buttons">
                    <div @click="save">
                        <div class="button green">
                            <span>{{ ! $route.params.id ? $t('user_form.add_user') : $t('user_form.save_changes') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="body" v-if="$route.params.id">
                <div class="tabs">
                    <router-link tag="div"
                                 to="algemeen">
                        {{ $t('user_form.general_data') }}
                    </router-link>
                    <router-link tag="div"
                                 to="documenten">
                        {{ $t('user_form.documents') }}
                    </router-link>
                </div>
                <router-view />
            </div>
        </div>
        <div class="content-wrap">
            <div class="content-wrap__content">
                <div class="panel panel--push-top">
                    <h2 class="title title--push-bottom">{{ $t('user_form.data') }}</h2>

                    <div class="field" v-if="!! $route.params.id">
                        <label class="label" for="afasId">{{ $t('user_form.afas_number') }}</label>
                        <input :value="afasId" disabled id="afasId" type="text" />
                    </div>

                    <div class="field">
                        <label class="label" for="name">{{ $t('user_form.name') }}</label>
                        <input :disabled="!!afasId || isEmploymentEnded" id="name" type="text" v-model="name" />
                    </div>

                    <div class="field">
                        <label class="label" for="email">{{ $t('user_form.email') }}</label>
                        <input :disabled="!!afasId || isEmploymentEnded" id="email" type="text" v-model="email" />
                    </div>

                    <div class="field">
                        <label class="label" for="division">{{ $t('user_form.divisions') }}</label>
                        <vue-multiselect
                            :disabled="!!afasId || isEmploymentEnded"
                            :multiple="false"
                            :options="businessUnitList"
                            :searchable="false"
                            class="multiselect--push-bottom"
                            deselectLabel=""
                            id="divisions"
                            label="name"
                            selectLabel=""
                            selectedLabel=""
                            track-by="id"
                            v-model="businessUnit">
                            <template slot="tag" slot-scope="props">
                                <span v-text="props.option.name + ', '"></span>
                            </template>
                            <template slot="option" slot-scope="props">
                                <div class="option__desc">
                                    <span class="option__title">{{ props.option.name }}</span>
                                    <img alt="check" class="selected" src="/svg/check.svg">
                                </div>
                            </template>
                            <span slot="noResult">{{ $t('user_form.no_results') }}</span>
                        </vue-multiselect>
                    </div>

                    <div class="field">
                        <label class="label" for="function">{{ $t('user_form.function') }}</label>
                        <vue-multiselect
                            :disabled="!!afasId || isEmploymentEnded"
                            :multiple="false"
                            :options="employeeFunctionList"
                            :searchable="false"
                            class="multiselect--push-bottom"
                            deselectLabel=""
                            id="functions"
                            label="name"
                            selectLabel=""
                            selectedLabel=""
                            track-by="id"
                            v-model="employeeFunction">
                            <template slot="tag" slot-scope="props">
                                <span v-text="props.option.name + ', '"></span>
                            </template>
                            <template slot="option" slot-scope="props">
                                <div class="option__desc">
                                    <span class="option__title">{{ props.option.name }}</span>
                                    <img alt="check" class="selected" src="/svg/check.svg">
                                </div>
                            </template>
                            <span slot="noResult">{{ $t('user_form.no_results') }}</span>
                        </vue-multiselect>
                    </div>

                    <div class="field">
                        <label class="label" for="employee_number">{{ $t('user_form.employee_number') }}</label>
                        <input :disabled="!!afasId || isEmploymentEnded" id="employee_number" type="text" v-model="employeeNumber" />
                    </div>

                    <div class="field">
                        <label class="label" for="phone">{{ $t('user_form.phone') }}</label>
                        <input :disabled="!!afasId || isEmploymentEnded" id="phone" type="text" v-model="phonenumber" />
                    </div>

                    <div class="field">
                        <label class="label" for="street">{{ $t('user_form.street') }}</label>
                        <input :disabled="!!afasId || isEmploymentEnded" id="street" type="text" v-model="street" />
                    </div>

                    <div class="field">
                        <label class="label" for="streetNumber">{{ $t('user_form.number') }}</label>
                        <input :disabled="!!afasId || isEmploymentEnded" id="streetNumber" type="text" v-model="streetNumber" />
                    </div>

                    <div class="field">
                        <label class="label" for="city">{{ $t('user_form.city') }}</label>
                        <input :disabled="!!afasId || isEmploymentEnded" id="city" type="text" v-model="city" />
                    </div>

                    <div class="field">
                        <label class="label" for="country">{{ $t('user_form.country') }}</label>
                        <vue-multiselect
                            :disabled="!!afasId || isEmploymentEnded"
                            :multiple="false"
                            :options="countries"
                            :searchable="false"
                            class="multiselect--push-bottom"
                            deselectLabel=""
                            id="country"
                            selectLabel=""
                            selectedLabel=""
                            v-model="country">
                            <template slot="tag" slot-scope="props">
                                <span v-text="props.option"></span>
                            </template>
                            <template slot="option" slot-scope="props">
                                <div class="option__desc">
                                    <span class="option__title">{{ props.option }}</span>
                                    <img alt="check" class="selected" src="/svg/check.svg">
                                </div>
                            </template>
                            <span slot="noResult">{{ $t('user_form.no_results') }}</span>
                        </vue-multiselect>
                    </div>

                    <div class="field">
                        <label for="date" class="label">{{ $t('user_form.employment_start_date') }}</label>
                        <datepicker :disabled="isEmploymentEnded || !!afasId"
                                    format=" dd-MM-yyyy"
                                    :placeholder="$t('event_form.pick_date')"
                                    v-model="employmentStartDate" />
                    </div>

                    <div class="field">
                        <label for="date" class="label">{{ $t('user_form.employment_end_date') }}</label>
                        <datepicker :disabled="isEmploymentEnded || !!afasId"
                                    format=" dd-MM-yyyy"
                                    :placeholder="$t('event_form.pick_date')"
                                    v-model="employmentEndDate" />
                    </div>

                    <div class="field field--checkbox" :class="{'disabled':isEmploymentEnded}">
                        <input :disabled="isEmploymentEnded" id="isActive" type="checkbox" v-model="isActive" />
                        <label for="isActive"></label>
                        <label class="label" for="isActive">{{ $t('user_form.active_user') }}</label>
                    </div>

                    <div class="field field--checkbox">
                        <input id="hasVisibleAnniversary" type="checkbox" v-model="hasVisibleAnniversary" />
                        <label for="hasVisibleAnniversary"></label>
                        <label class="label" for="hasVisibleAnniversary">{{ $t('user_form.visible_anniversary') }}</label>
                    </div>

                    <div class="field">
                        <label for="roles">{{ $t('user_form.roles') }}</label>
                        <vue-multiselect
                            class="multiselect--push-bottom"
                            :options="rolesList"
                            label="name"
                            :allow-empty="true"
                            deselectLabel=""
                            id="roles"
                            selectLabel=""
                            selectedLabel=""
                            track-by="id"
                            :multiple="true"
                            v-model="roles">
                            <template slot="tag" slot-scope="props">
                                <span v-text="props.option.name + ', '"></span>
                            </template>
                            <template slot="option" slot-scope="props">
                                <div class="option__desc">
                                    <span class="option__title">{{ props.option.name }}</span>
                                    <img alt="check" class="selected" src="/svg/check.svg">
                                </div>
                            </template>
                            <span slot="noResult">{{ $t('user_form.no_results') }}</span>
                        </vue-multiselect>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-wrap" v-if="$route.params.id">
            <div class="content-wrap__content">
                <div class="panel panel--push-top">
                    <h2 class="title title--push-bottom">{{ $t('user_form.view_news_items') }}</h2>
                    <div class="field">
                        <label class="label" for="divisionNews">{{ $t('user_form.news_from_division') }}</label>
                        <vue-multiselect
                            :disabled="isEmploymentEnded"
                            :close-on-select="false"
                            :multiple="true"
                            :options="businessUnitList"
                            :searchable="false"
                            class="multiselect--push-bottom"
                            deselectLabel=""
                            id="divisionNews"
                            label="name"
                            placeholder="Selecteer divisies"
                            selectLabel=""
                            selectedLabel=""
                            track-by="id"
                            v-model="contentBusinessUnits">
                            <template slot="tag" slot-scope="props">
                                <span v-text="props.option.name + ', '"></span>
                            </template>
                            <template slot="option" slot-scope="props">
                                <div class="option__desc">
                                    <span class="option__title">{{ props.option.name }}</span>
                                    <img alt="check" class="selected" src="/svg/check.svg">
                                </div>
                            </template>
                            <span slot="noResult">{{ $t('user_form.no_results') }}</span>
                        </vue-multiselect>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-wrap" v-if="$route.params.id">
            <div class="content-wrap__content">
                <div class="panel panel--push-top">
                    <h2 class="title title--push-bottom">{{ $t('user_form.available_payslips') }}</h2>

                    <table class="table-minimal" v-if="payslips">
                        <tr :key="'payslip_' + payslip.Dossieritem" v-for="payslip in payslips">
                            <td>{{ payslip.Onderwerp }}</td>
                        </tr>
                    </table>

                    <p v-if="! payslips.length">{{ $t('user_form.no_payslips') }}</p>
                </div>
            </div>
        </div>

        <div class="content-wrap" v-if="$route.params.id">
            <div class="content-wrap__content">
                <div class="panel panel--push-top">
                    <h2 class="title title--push-bottom">{{ $t('user_form.available_wage_supplements') }}</h2>

                    <table class="table-minimal" v-if="fleethours">
                        <tr :key="'fleethour_' + fleethour.Dossieritem" v-for="fleethour in fleethours">
                            <td>{{ fleethour.Onderwerp }}</td>
                        </tr>
                    </table>

                    <p v-if="! fleethours.length">{{ $t('user_form.no_wage_supplements') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VueMultiselect from "vue-multiselect/src/Multiselect";
    import Datepicker from "vuejs-datepicker";
    import moment from 'moment';
    import { BusinessUnitService } from "../../services";

    export default {
        name: 'users-form',

        components: { VueMultiselect, Datepicker },

        beforeRouteEnter(to, from, next) {
            const userId = to.params.id;

            if (userId) {
                window.axios.get(`/users/${userId}`).then((response) => {
                    const user = response.data;

                    const roles = ['admin', 'employee'];

                    next(vm => {
                        vm.afasId = user.afas_id;
                        vm.employeeFunction = user.employee_function;
                        vm.businessUnit = user.business_unit;
                        vm.contentBusinessUnits = user.content_business_units;
                        vm.roles = user.visible_roles;
                        vm.name = user.name;
                        vm.email = user.email;
                        vm.phonenumber = user.phonenumber;
                        vm.street = user.street;
                        vm.streetNumber = user.street_number;
                        vm.city = user.city;
                        vm.country = user.country;
                        vm.isActive = user.is_active;
                        vm.employeeNumber = user.employee_number;
                        vm.hasVisibleAnniversary = user.has_visible_anniversary;
                        vm.employmentStartDate = user.employment_start_date !== null ? user.employment_start_date : null;
                        vm.employmentEndDate = user.employment_end_date !== null ? user.employment_end_date : null;
                        vm.markAsAdmin = user.visible_roles.map(r => r.name.toLowerCase()).filter(r => roles.indexOf(r) !== -1).length === roles.length;
                    });
                }).catch((err) => {
                    next({ name: 'users.index' });
                });

                return;
            }

            next();
        },

        data() {
            return {
                businessUnitList: [],
                employeeFunctionList: [],
                countries: [
                    'NL',
                    'PL',
                    'D',
                    'F',
                    'B',
                    'RO',
                    'GB',
                    'LT',
                    'BG',
                    'GR',
                    'LV',
                    'S',
                    'MD',
                ],

                afasId: null,
                employeeFunction: null,
                businessUnit: null,
                contentBusinessUnits: [],
                roles: [],
                name: null,
                email: null,
                phonenumber: null,
                street: null,
                streetNumber: null,
                employeeNumber: null,
                city: null,
                country: null,
                isActive: true,
                hasVisibleAnniversary: true,
                employmentStartDate: null,
                employmentEndDate: null,
                markAsAdmin: false,
                payslips: [],
                fleethours: [],
                rolesList: [],
            };
        },

        beforeMount() {
            this.getBusinessUnits().then(() => {
                this.contentBusinessUnits = this.contentBusinessUnits.filter((d) => {
                    return !!this.businessUnitList.find(b => b.id === d.id);
                });
            });

            this.getRoles();
            this.getEmployeeFunctions();

            if (this.$route.params.id) {
                this.fetchUserPayslips();
                this.fetchUserFleetHours();
            }
        },

        computed: {
            isEmploymentEnded() {
                return this.employmentEndDate && this.employmentEndDate <= moment().subtract(1, 'years');
            },
        },

        methods: {
            getBusinessUnits() {
                return BusinessUnitService.fetchByPermission(this.$permissions.ADD_AND_EDIT_USERS).then((response) => {
                    this.businessUnitList = response.data;

                    return response;
                });
            },

            getRoles() {
                window.axios.get('/roles/visible').then((response) => {
                    this.rolesList = response.data
                });
            },

            getEmployeeFunctions() {
                window.axios.get('/employee-functions').then((response) => {
                    this.employeeFunctionList = response.data
                });
            },

            fetchUserPayslips() {
                const userId = this.$route.params.id;

                window.axios.get(`/users/${userId}/payslips`).then((response) => {
                    this.payslips = response.data;
                });
            },

            fetchUserFleetHours() {
                const userId = this.$route.params.id;

                window.axios.get(`/users/${userId}/salary-attachments`).then((response) => {
                    this.fleethours = response.data;
                });
            },

            isAdmin() {
                return !!this.roles.find(r => r.name === 'admin') && this.roles.length === 1;
            },

            save() {
                const userId = this.$route.params.id;

                const params = {
                    businessUnit: this.businessUnit ? this.businessUnit.id : null,
                    employeeFunction: this.employeeFunction ? this.employeeFunction.id : null,
                    contentBusinessUnits: this.contentBusinessUnits.map(b => b.id),
                    name: this.name,
                    email: this.email,
                    phonenumber: this.phonenumber,
                    street: this.street,
                    streetNumber: this.streetNumber,
                    city: this.city,
                    country: this.country,
                    isActive: this.isActive,
                    hasVisibleAnniversary: this.hasVisibleAnniversary,
                    roles: this.roles,
                    employee_number: this.employeeNumber,
                    employmentEndDate: this.employmentEndDate ? moment(this.employmentEndDate).format('YYYY-MM-DD') : null,
                    employmentStartDate: this.employmentStartDate ? moment(this.employmentStartDate).format('YYYY-MM-DD') : null,
                    ...(!this.isAdmin() && { markAsAdmin: this.markAsAdmin }),
                };

                if (userId) {
                    this.update(userId, params);
                    return;
                }

                this.store(params);
            },

            store(params) {
                window.axios.post('/users', params).then((response) => {
                    this.$flashMessage(this.$t('user_form.user_saved'), 5000, 'succes');
                    this.$router.push({ name: 'users.edit', params: { id: response.data.id } });
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors).map((err) => err[1]).flat().join('<br>');
                    this.$flashMessage(errors, 5000, 'error');
                });
            },

            update(userId, params) {
                window.axios.put(`/users/${userId}`, params).then((response) => {
                    this.$flashMessage(this.$t('user_form.user_saved'), 5000, 'succes');
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors).map((err) => err[1]).flat().join('<br>');
                    this.$flashMessage(errors, 5000, 'error')
                });
            },
        },
    }
</script>
